/* eslint-disable no-console */
import { register } from 'register-service-worker'
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() { },
    registered() { },
    cached() { },
    updatefound() {
      caches.keys().then(cns => {
        cns.forEach(cn => { caches.delete(cn); });
      });
      window.location.reload(true)
    },
    updated() { },
    offline() { },
    error(error) { }
  })
}